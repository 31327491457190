export const pageTitle = 'Banery reklamowe odblaskowe';
export const pageDescription = 'Zajmujemy się projektowaniem oraz drukowaniem wysokiej jakości reklam w postaci banerów odblaskowych. Skorzystaj z usług naszej drukarni i złóż zamówienie!';
export const mainHeader = 'Baner reklamowy odblaskowy';
export const title = 'Baner odblaskowy';

export const bannerInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**${title} <span class="orange">czas realizacji</span>**
      \n 2-3 dni robocze`,
    },
    {
      article: `**${title} <span class="orange">koszt wysyłki</span>**
      \n 30,00 zł/netto. W jednej paczce zmieści się ok. 30m2 banera.`,
    },
    {
      article: `**${title} <span class="orange">wymiar</span>**
      \n Maksymalna możliwa szerokość banera w jednym kawałku to 500cm. Przy większych formatach banery są łączone z brytów.
      \n Najczęściej zamawiane wymiary banerów odblaskowych to::
      \n * ${title} 1,5 x 1 m
      \n * ${title} 1,5 x 1,5 m
      \n * ${title} 2 x 1 m
      \n * ${title} 2 x 2 m
      \n * ${title} 2,5 x 1 m
      \n * ${title} 3 x 1 m
      `,
    },
  ],
  rightColumn: [
    {
      article: `**${title} <span class="orange">opis</span>**
      \n Materiał o strukturze mikro-pryzmatycznych soczewek “plastra miodu” o wysokich parametrach odblaskowości. 
      \n Materiał o gramaturze 540g/m2 i przeplotem 200x500.
      \n Kolorystyka: CMYK.
      \n Rozdzielczość zadruku: 720 dpi.
      \n Wydruk wykończony poprzez zgrzanie krawędzi i zaoczkowanie co 50cm.
      Na życzenie Klienta jest możliwość dodatkowego gęstszego oczkowania.
      \n Gotowy **baner reklamowy** można zamontować poprzez oczka przy użyciu np. opasek zaciskowych tzw. trytytek czy linek/gum. Możliwe jest również wykończenie zadrukowanego banera tzw. tunelami, w które można wsunąć metalowe profile, drewniane kołki czy przeciągnąć linkę.
      \n Należy pamiętać że materiał odblaskowy tylko odbija zewnętrzne prostopadłe źródło światła, dlatego sugeruje się wykorzystanie tego typu materiału np. przy słabo oświetlonej drodze.
      \n Przeznaczony do większych rozmiarów reklamy i ekspozycji, które są bardziej narażone na silniejsze podmuchy wiatru.
      \n Druk wykonywany jest najtrwalszą techniką druku solwentowego tj. twardy solwent. Jego trwałość szacuje się na ok. kilka lat. Jest on odporny na czynniki atmosferyczne, w tym także promieniowanie UV. Do zalet tego rodzaju druku, zalicza się brak konieczności laminowania wydruków. Ponadto, cechuje go odporność na odbarwienia, wodę i ścieranie.
      `,
    },
    {
      article: `**Baner odblaskowy <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
